@tailwind base;
@tailwind components;
@tailwind utilities;



/*// <uniquifier>: Use a unique and descriptive class name*/
/*// <weight>: Use a value from 100 to 900*/

/*.inter-<uniquifier> {*/
/*          font-family: "Inter", sans-serif;*/
/*          font-optical-sizing: auto;*/
/*          font-weight: <weight>;*/
/*          font-style: normal;*/
/*          font-variation-settings:*/
/*                  "slnt" 0;*/
/*        }*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
